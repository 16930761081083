<template>
    <user-dashboard-layout>
        <v-data-table
            v-if="categories.data"
            :loading="loading"
            :page="categories.meta.current_page"
            :items-per-page="categories.meta.per_page * 1"
            :server-items-length="categories.meta.total"
            @pagination="paginate"
            :headers="headers"
            :items="categories.data"
            class="elevation-1"
        >
            <template v-slot:header="{ props }">
                <thead>
                <tr>
                    <th v-for="header in props.headers" :key="header.text">
                        {{ $t('general.' + header.text.toLowerCase()) }}
                    </th>
                </tr>
                </thead>
            </template>
            <template v-slot:item.extra_processing_time="{ item }">
                {{ Math.trunc(item.extra_processing_time) }}
            </template>
            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title>{{ $t('general.my-category') }}</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <v-dialog v-model="dialog" max-width="500px">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                color="primary"
                                dark
                                class="mb-2"
                                v-bind="attrs"
                                v-on="on"
                            >
                                {{ $t('general.new-item') }}
                            </v-btn>
                        </template>
                        <v-card>
                            <v-card-title>
                                <span class="text-h5">{{ formTitle }}</span>
                            </v-card-title>

                            <v-card-text>
                                <v-container>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-text-field
                                                v-model="category.name"
                                                outlined
                                                dense
                                                :label="$t('general.category-name')"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-text-field
                                                v-model="category.extra_processing_time"
                                                type="number"
                                                min="0"
                                                value="0"
                                                step="1"
                                                outlined
                                                dense
                                                :label="$t('general.extra_processing_time')"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>

                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="close"
                                >
                                    {{ $t('general.cancel') }}
                                </v-btn>
                                <v-btn color="blue darken-1" text @click="save">
                                    {{ $t('general.save') }}
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <v-dialog v-model="dialogDelete" max-width="500px">
                        <v-card>
                            <v-card-title class="text-h5"
                                >{{ $t('general.are-you-sure-you-want') }}</v-card-title
                            >
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="closeDelete"
                                    >{{ $t('general.cancel') }}</v-btn
                                >
                                <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="deleteItemConfirm"
                                    >{{ $t('general.ok') }}</v-btn
                                >
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-toolbar>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-icon small class="mr-2" @click="editItem(item)">
                    mdi-pencil
                </v-icon>
                <v-icon small @click="deleteItem(item)">
                    mdi-delete
                </v-icon>
            </template>
        </v-data-table>
    </user-dashboard-layout>
</template>

<script>
import UserDashboardLayout from "../../../layouts/UserDashboardLayout";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
    components: { UserDashboardLayout },
    data: () => ({
        loading: false,
        dialog: false,
        dialogDelete: false,
        headers: [
            {
                text: "Name",
                align: "start",
                sortable: false,
                value: "name"
            },
            {
                text: "extra_processing_time",
                value: "extra_processing_time"
            },
            {
                text: "Actions",
                value: "actions"
            }
        ],
        editedIndex: -1,
        pagination: {
            page: 1,
            itemsPerPage: 5
        }
    }),

    computed: {
        ...mapGetters(["defaultCategory", "category", "categories"]),
        formTitle() {
            return this.editedIndex === -1 ? "New Item" : "Edit Item";
        }
    },

    watch: {
        dialog(val) {
            val || this.close();
        },
        dialogDelete(val) {
            val || this.closeDelete();
        }
    },
    mounted() {
        this.init();
    },

    methods: {
        ...mapActions([
            "storeCategory",
            "setCategories",
            "updateCategory",
            "destroyCategory"
        ]),
        ...mapMutations(["setCategory"]),

        async init() {
            this.loading = true;
            await this.setCategories(this.pagination);
            this.loading = false;
        },
        editItem(item) {
            this.editedIndex = this.categories.data.findIndex(
                i => i.id === item.id
            );
            this.setCategory(item);
            this.dialog = true;
        },

        deleteItem(item) {
            this.editedIndex = this.categories.data.findIndex(
                i => i.id === item.id
            );
            this.setCategory(item);
            this.dialogDelete = true;
        },

        async deleteItemConfirm() {
            await this.destroyCategory(this.category);
            this.init();
            this.closeDelete();
        },

        close() {
            this.dialog = false;
            this.$nextTick(() => {
                this.setCategory(this.defaultCategory);
                this.editedIndex = -1;
            });
        },

        closeDelete() {
            this.dialogDelete = false;
            this.$nextTick(() => {
                this.setCategory(this.defaultCategory);
                this.editedIndex = -1;
            });
        },

        async save() {
            if (this.editedIndex > -1) {
                await this.updateCategory(this.category);
                this.init();
            } else {
                await this.storeCategory(this.category);
                this.init();
            }
            this.close();
        },
        paginate(pagination) {
            this.pagination = pagination;
            this.setCategories(pagination);
        }
    }
};
</script>

<style>
thead.v-data-table-header {
    display: none !important;
}
</style>
